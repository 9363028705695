var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "search-ip" } }, [
    _c("div", [
      _c("div", [
        _c("input", {
          attrs: { type: "button", value: "IPを取得" },
          on: { click: _vm.getIp }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }