<template>
<!--  <div id="app">
  {{ info }}
</div> -->
  <div id="search-ip">
    <div>
      <div>
        <input @click="getIp" type="button" value="IPを取得">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// Ajax通信ライブラリ


  export default {
    name: 'TestTable',
    methods: {
      getIp() {
        this.axios.get('https://us-central1-ocdev-19b5c.cloudfunctions.net/app/api/getCouponDetail/hBQFcpxZja1TJxJcXCwz')
          .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            alert(e);
          });
      }
    }
  }


</script>